import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/base/banner/pc/list', params);

export const listNew = params => get('/web/base/banner/new/list', params);

export const listSecond = params => get('/web/base/banner/second/list', params);

export const listTenancy = params => get('/web/base/banner/tenancy/list', params);

export const listGoods = params => get('/web/base/banner/goods/list', params);

export const add = params => post('/web/base/banner/pc/add', params);

export const update = params => put('/web/base/banner/pc/state/update', params);

export const view = params => get('/web/base/banner/pc/view/' + params.id, '');

export const updateNew = params => put('/web/base/banner/pc/update', params);

export const deleteNew = params => Delete('/web/base/banner/pc/delete/' + params.id, '');